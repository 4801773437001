header {
    padding: 12px 16px 12px 16px;
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 10px;
    align-items: center;
    background: var(--bg-accent);
    align-content: center;
    width: 100%;
    z-index: 1111;
    position: relative;
    box-sizing: border-box;
    color: var(--text-main);

}

header > div {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-content: center;
}
