.month-label {
    padding: 12px 16px;
    text-align: left;
    font-size: 10px;
    text-transform: uppercase;
    background: var(--bg-accent);
    position: sticky;
    top: 0;
    z-index: 1000;
    border-top: 1px var(--border-main) solid;
    border-bottom: 1px var(--border-main) solid;
    color: var(--text-secondary);
    margin: 4px 0;
}

.month-label:first-child {
    margin-top: 0;
}
