.item {
    display: grid;
    grid-template-columns: min-content 1fr 70px;
    white-space: nowrap;
    gap: 10px;
    justify-content: space-between;
    padding: 6px 16px;
    align-items: center;
    /*opacity: 0.7;*/

}
.item:first-child {
    padding-top: 0;
    margin-top: 8px;
}

.item.overridden {
    filter: grayscale(1) opacity(0.5);
}

.item-container {
    position: relative;
}

.item-container .line-through {
    height: 1px;
    width: calc(100% - 20px);
    background: var(--text-main);
    opacity: 0.3;
    position: absolute;
    top: 2px;
    bottom: 0;
    margin: auto 10px;
}

.item > *:last-child {
    text-align: right;
}

.badge {
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    font-family: monospace;
    color: #fff;
    width: 40px;
    box-sizing: border-box;
    text-align: center;
    opacity: 0.8;
}

.badge-3478 { background: #C51162 }
.badge-3101 { background: #311B92 }
.badge-3472 { background: #7B1FA2 }
.badge-3468 { background: #0091EA }
.badge-3482 { background: #F44336 }
.badge-3498 { background: #689F38 }
.badge-3103 { background: #00897B }
