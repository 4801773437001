.calendar-icon {
    background: #fff;
    width: 4em;
    height: 4em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /*padding: 0.em 0;*/
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 10px;
    color: #000000;
    border: 1px var(--border-main) solid;
}

.calendar-icon > *:first-child {
    text-transform: uppercase;
    color: #E53935;
    font-size: 0.75em;
    font-weight: 800;
}

.calendar-icon > *:last-child {
    font-size: 2em;
}
