html {
    --bg-main: #050f1f;
    --bg-accent: #101827;
    --border-main: #050f1f;
    --text-main: #fff;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, #root, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    background: var(--bg-main);
    -webkit-text-size-adjust: none
}

@media (prefers-color-scheme: dark) {
    html {
        --bg-accent: #171717;
        --bg-main: #1c1c1c;
        --text-main: #ffffff;
        --text-secondary: #ababab;
        --border-main: #2f2f2f;
    }
}

@media (prefers-color-scheme: light) {
    html {
        --bg-accent: #ffffff;
        --bg-main: #ffffff;
        --text-main: #000000;
        --text-secondary: #797979;
        --border-main: #f3f3f3;
    }
}

main {
    color: var(--text-main);
    overflow: auto;
    display: grid;
    box-sizing: border-box;
    z-index: 11111;
}
